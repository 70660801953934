import React, { useState, useEffect } from 'react';
import axios from 'axios'; // For API calls
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import '../styles/Home.css';

Chart.register(ArcElement, Tooltip, Legend);

const Home = ({ orderCount, groups }) => {
  const [products, setProducts] = useState([]);
  const [employeeName, setEmployeeName] = useState('');
  const [employeeOrg, setEmployeeOrg] = useState('');
  const [movements, setMovements] = useState([]);

  useEffect(() => {
    const name = localStorage.getItem('employeeName');
    const org = localStorage.getItem('employeeOrg');
    if (name && org) {
      setEmployeeName(name);
      setEmployeeOrg(org);
    }
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('http://localhost:8080/api/products');
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    const fetchMovements = async () => {
      try {
        const response = await axios.get('http://localhost:8080/api/barcode/movements');
        setMovements(response.data);
      } catch (error) {
        console.error('Error fetching movements:', error);
      }
    };

    fetchMovements();
  }, []);

  const totalProducts = products.length;
  const productsIn = movements.filter((movement) => movement.status === 'In').length;
  const productsOut = movements.filter((movement) => movement.status === 'Out').length;
  const activeProducts = movements.filter((movement) => movement.status === 'In' || movement.status === 'Out').length;
  const inactiveProducts = movements.filter((movement) => movement.status === 'Inactive').length;
  const unreturnableProducts = movements.filter((movement) => movement.status === 'Unreturnable').length;
  const lowStockItems = products.filter((product) => product.stock && product.stock <= 10).length;

  const doughnutData = {
    labels: ['Products In', 'Products Out'],
    datasets: [
      {
        data: [productsIn, productsOut],
        backgroundColor: ['#4caf50', '#a3dbb8'],
        hoverBackgroundColor: ['#4caf50', '#a3dbb8'],
        borderWidth: 1,
      },
    ],
  };

  const productStatusData = {
    labels: ['Active Products', 'Inactive Products'],
    datasets: [
      {
        data: [activeProducts, inactiveProducts],
        backgroundColor: ['#039da8', '#79aaad'],
        hoverBackgroundColor: ['#039da8', '#79aaad'],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="page">
      <div className="welcome-section">
        <div className="welcome-icon">
          <i className="fas fa-user-circle"></i>
        </div>
        <div className="welcome-text">
          <h1>Hello, {employeeName || "User"}</h1>
          <p>{employeeOrg || "Warehouse Management System"}</p>
        </div>
      </div>

      <div className="dashboard-container">
        <div className="sales-activity">
          <h3>Warehouse Activity</h3>
          <div className="card-container">
            <div className="card">
              <h4>Total Products</h4>
              <p className="quantity" style={{ color: 'blue' }}>{totalProducts}</p>
            </div>
            <div className="card">
              <h4>Products In</h4>
              <p className="quantity" style={{ color: 'green' }}>{productsIn}</p>
            </div>
            <div className="card">
              <h4>Products Out</h4>
              <p className="quantity" style={{ color: 'red' }}>{productsOut}</p>
            </div>
            <div className="card">
              <h4>Special Category</h4>
              <p className="quantity" style={{ color: 'orange' }}>{orderCount}</p>
            </div>
          </div>
        </div>

        <div className="container">
          <h3 className="section-title">Product Details</h3>
          <div className="product-details-container">
            <div className="product-details-content">
              <div className="product-info">
                <p><span className="low-stock">Low Stock Items</span> <strong>{lowStockItems}</strong></p>
                <p id="pp">All Item Groups <strong margi>{groups.length}</strong></p>
                <p id="ps">All Items <strong>{totalProducts}</strong></p>
              </div>
            </div>
            <div className="divider"></div>
            <div className="doughnut-chart">
              <Doughnut
                data={doughnutData}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      position: 'right',
                      align: 'center',
                      labels: {
                        usePointStyle: true,
                      },
                    },
                  },
                }}
                width={200}
                height={200}
              />
            </div>
          </div>
        </div>

        <div className="container">
          <h3 className="section-title">Product Status</h3>
          <div className="product-details-container">
            <div className="product-details-content">
              <div className="product-info">
                <p><span className="status-label">Active Products</span> <strong  style={{ marginLeft: '287px' }}>{activeProducts}</strong></p>
                <p><span className="status-label">Inactive Products</span> <strong  style={{ marginLeft: '272px' }}>{inactiveProducts}</strong></p>
                <p><span className="status-label">Unreturnable Products</span> <strong  style={{ marginLeft: '230px' }}>{unreturnableProducts}</strong></p>
              </div>
            </div>
            <div className="divider"></div>
            <div className="doughnut-chart">
              <Doughnut style={{marginRight:'15px'}}
                data={productStatusData}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      position: 'right',
                      align: 'center',
                      labels: {
                        usePointStyle: true,
                      },
                    },
                  },
                }}
                width={200}
                height={200}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
