import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { TextField, Button, MenuItem, Select, InputLabel, FormControl, CircularProgress, Paper } from '@mui/material';

const CreateUserPage = () => {
  const [users, setUsers] = useState([]);  // List of all users fetched
  const [selectedUser, setSelectedUser] = useState(null);  // Selected user for the form
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('');
  const [name, setName] = useState('');  // State for the full name
  const [email, setEmail] = useState('');  // State for the email
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  // Fetch all users for full name and email
  useEffect(() => {
    axios.get('http://localhost:8080/api/users/all')
      .then((response) => {
        setUsers(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching users:', error);
        setIsLoading(false);
      });
  }, []);

  // Handle form submission to create a new user
  const handleSubmit = async (e) => {
    e.preventDefault();

    const newUser = {
      username,
      password,
      role,
      name,   // Include the full name
      email,  // Include the email
    };

    try {
      await axios.post('http://localhost:8080/api/logins', newUser);
      alert('User created successfully!');
      navigate('/users'); // Redirect to users list after creation
    } catch (error) {
      console.error('Error creating user:', error);
      alert('There was an error creating the user.');
    }
  };

  // Handle form changes
  const handleUsernameChange = (e) => setUsername(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);
  const handleRoleChange = (e) => setRole(e.target.value);
  const handleNameChange = (e) => setName(e.target.value);  // This is the handler for name
  const handleEmailChange = (e) => setEmail(e.target.value);  // This is the handler for email

  // Handle user selection from the dropdown
  const handleUserSelection = (e) => {
    const selected = users.find(user => user.id === e.target.value);
    setSelectedUser(selected);
    setName(selected.name);   // Set the name field from selected user
    setEmail(selected.email); // Set the email field from selected user
    setPassword(selected.password);
    setUsername(selected.username); // Set the username field
  };

  // Navigate to the users table page
  const handleViewTableClick = () => {
    navigate('/createuser-table');
  };

  return (
    <div className="create-user-page" style={{marginLeft:'220px',marginTop:'40px'}}>
      <h2  style={{marginLeft:'420px',marginBottom:'10px'}}>Create New User</h2>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Paper style={{ padding: '20px', maxWidth: '500px', marginTop: 'auto', marginLeft: '300px' }}>
          <form onSubmit={handleSubmit}>
            {/* Dropdown to select a user */}
            <FormControl fullWidth required style={{ marginBottom: '15px' }}>
              <InputLabel>Full Name</InputLabel>
              <Select
                value={selectedUser ? selectedUser.id : ''}
                onChange={handleUserSelection}
                label="Full Name"
              >
                {users.map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    {user.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Display selected user's full name and email */}
            {selectedUser && (
              <>
                <TextField
                  label="Full Name"
                  value={name}  // Bind the name to state
                  onChange={handleNameChange}  // Update state when name changes
                  fullWidth
                  required
                  style={{ marginBottom: '15px' }}
                />
                <TextField
                  label="Email"
                  value={email}  // Bind the email to state
                  onChange={handleEmailChange}  // Update state when email changes
                  fullWidth
                  required
                  style={{ marginBottom: '15px' }}
                />

                <TextField
                  label="Password"
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                  fullWidth
                  required
                  style={{ marginBottom: '15px' }}
                />
              </>
            )}

            {/* Username input */}
            <TextField
              label="Username"
              value={username}
              onChange={handleUsernameChange}
              fullWidth
              required
              style={{ marginBottom: '15px' }}
            />

            {/* Role dropdown */}
            <FormControl fullWidth required style={{ marginBottom: '15px' }}>
              <InputLabel>Role</InputLabel>
              <Select
                value={role}
                onChange={handleRoleChange}
                label="Role"
              >
                <MenuItem value="Admin">Admin</MenuItem>
                <MenuItem value="Employee">Employee</MenuItem>
                <MenuItem value="Manager">Manager</MenuItem>
              </Select>
            </FormControl>

            <Button type="submit" variant="contained" color="primary" fullWidth>
              Create User
            </Button>
          </form>

          {/* View Table Button */}
          <Button 
            variant="outlined" 
            color="secondary" 
            fullWidth 
            style={{ marginTop: '15px' }}
            onClick={handleViewTableClick}
          >
            View User Table
          </Button>
        </Paper>
      )}
    </div>
  );
};

export default CreateUserPage;
