import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Modal from './Modal.js'; // Assuming this is your modal component
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Paper, TablePagination } from '@mui/material'; // Material UI components
import '../styles/user.css'; // Import the user CSS styles
import '../styles/modal.css';

const UserPage = ({ setUsers }) => {
  const [users, setLocalUsers] = useState([]); // Local state for user data
  const [selectedUser, setSelectedUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(0); // State for the current page
  const [rowsPerPage, setRowsPerPage] = useState(10); // State for the number of rows per page
  const navigate = useNavigate();

  // Fetch users from the backend on component mount
  useEffect(() => {
    fetch('http://localhost:8080/api/users/all')
      .then((response) => response.json())
      .then((data) => {
        console.log('Fetched users:', data); // Debugging the data
        setLocalUsers(data); // Set the fetched users into state
        setUsers(data); // Set the users in parent state (App.js)
      })
      .catch((error) => {
        console.error('Error fetching users:', error);
      });
  }, [setUsers]); // Empty dependency array ensures it runs once when component mounts

  // Navigate to Add User Page
  const goToAddUserPage = () => {
    navigate('/newuser');
  };

  // Handle when a user clicks on a user's name
  const handleUserClick = (user) => {
    setSelectedUser(user);
    setIsModalOpen(true); // Open the modal
  };

  // Handle close modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  // Handle Delete User with confirmation
  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this user?");

    if (confirmDelete) {
      try {
        await axios.delete(`http://localhost:8080/api/users/${id}`); // Make DELETE request to your backend
        // Remove the deleted user from the local state (frontend)
        setLocalUsers(users.filter(user => user.id !== id)); // Update the state to reflect the deletion
      } catch (error) {
        console.error('There was an error deleting the user:', error);
      }
    } else {
      console.log("User deletion cancelled.");
    }
  };

  // Navigate to Edit User page
  const handleEditClick = (user) => {
    navigate(`/newuser`, { state: { user } }); // Passing user data via location state
  };

  // Handle the update after an edit
  const updateUserData = (updatedUser) => {
    const updatedUsers = users.map(user => 
      user.id === updatedUser.id ? updatedUser : user
    );
    setLocalUsers(updatedUsers); // Update the local users state
  };

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to 0 when rows per page changes
  };

  // Slice the users to display only the current page's data
  const displayedUsers = users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <div className="user-page">
      <div className="header">
        <h2>All Users</h2>
        <div className="invite-button-container">
          <Button variant="contained" color="primary" onClick={goToAddUserPage}>
            Add User
          </Button>
        </div>
      </div>

      <div className="user-list">
        <TableContainer component={Paper}>
          <Table aria-label="user table">
            <TableHead>
              <TableRow>
                <TableCell>Profile</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>User ID</TableCell>
                <TableCell>Email ID</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {displayedUsers.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>
                    <img
                      src={`http://localhost:8080/api/users/images/${user.id}`} // Fetch image from the backend using user ID
                      alt="User Avatar"
                      className="user-avatar"
                      style={{ width: 50, height: 50, borderRadius: '50%' }} // Adjust the style if needed
                    />
                  </TableCell>
                  <TableCell>
                    <span
                      className="user-name"
                      onClick={() => handleUserClick(user)}
                      style={{ cursor: 'pointer', color: 'black' }}
                    >
                      {user.name}
                    </span>
                  </TableCell>
                  <TableCell>{user.userid}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.role}</TableCell>
                  <TableCell>
                    {/* <Button variant="contained" color="primary" onClick={() => handleEditClick(user)} style={{ marginRight: 8 }}>
                      Edit
                    </Button> */}
                    <Button variant="contained" color="secondary" onClick={() => handleDelete(user.id)}>
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pagination Controls */}
        <TablePagination
          component="div"
          count={users.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10]} // Fixed to 10 rows per page
        />
      </div>

      {/* Render the modal if a user is selected */}
      <Modal show={isModalOpen} handleClose={closeModal} user={selectedUser} />
    </div>
  );
};

export default UserPage;
