import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import '../styles/barcode.css';

const BarcodeScanner = ({ addMovement = () => { } }) => {
  const [scannedBarcode, setScannedBarcode] = useState('');
  const [productName, setProductName] = useState('');
  const [status, setStatus] = useState('');
  const [password, setPassword] = useState('');
  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState('');
  const [error, setError] = useState('');
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [showConfirmButton, setShowConfirmButton] = useState(false);
  const [currentDate] = useState(new Date().toLocaleDateString());
  const hiddenInputRef = useRef(null);
  const [role, setRole] = useState(null);

    
  useEffect(() => {
    // Retrieve role from localStorage
    const storedRole = localStorage.getItem('userRole');
    setRole(storedRole); // Set the role from localStorage
  }, []);
  
  // Fetch product details by SKU
  const fetchProductName = async (sku) => {
    try {
      const response = await axios.get(`http://localhost:8080/api/products/sku/${sku}`);
      if (response.status === 200 && response.data) {
        setProductName(response.data.name); // Assuming `name` exists in the response
        setScannedBarcode(sku);
        setError('');
      } else {
        throw new Error('Product not found');
      }
    } catch (error) {
      const errorMessage = error.response?.data || 'Product not found.';
      setProductName('');
      setScannedBarcode('');
      setError(errorMessage);
    }
  };





  // Fetch user details by password
  const fetchUserDetails = async (password) => {
    try {
      const response = await axios.get(`http://localhost:8080/api/users/by-password/${password}`);
      if (response.status === 200 && response.data) {
        setUserId(response.data.userid); // Set user ID from the response
        setUserName(response.data.name); // Set user name from the response
        setError(''); // Clear any previous errors
        setShowConfirmButton(true); // Enable confirm button
      } else {
        throw new Error('User not found');
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
      setUserId(null);
      setUserName('');
      setError('Invalid password or user not found.');
      setShowConfirmButton(false);
    }
  };

  // Handle barcode input
  const handleInputChange = async (e) => {
    const barcode = e.target.value.trim();
    if (barcode) {
      setScannedBarcode(barcode); // Update the state immediately to show in SKU field
      await fetchProductName(barcode); // Fetch product details
    }
    e.target.value = ''; // Clear the input field after scanning
  };

  // Handle status change
  const handleStatusChange = (e) => {
    const selectedStatus = e.target.value;
    setStatus(selectedStatus);
    setShowPasswordField(selectedStatus !== '');
    setUserId(null);
    setUserName('');
    setPassword('');
  };

  // Handle password input
  const handlePasswordChange = (e) => {
    const enteredPassword = e.target.value.trim();
    setPassword(enteredPassword);
    if (enteredPassword) {
      fetchUserDetails(enteredPassword);
    } else {
      setUserId(null);
      setUserName('');
      setShowConfirmButton(false);
    }
  };

  // Handle form submission
  const handleConfirm = async () => {
    if (userId && scannedBarcode && status && productName) {
      const movement = {
        scannedBarcode,  // SKU
        productName,     // Product Name
        status,          // In/Out
        userId,          // User ID
        userName,        // User Name
        password,        // Password if applicable
      };

      console.log('Payload to be sent:', movement); // Debugging payload

      try {
        const response = await axios.post('http://localhost:8080/api/barcode/submit', movement);
        console.log('Response:', response); // Debugging response
        if (response.status === 201) {
          alert('Product movement added successfully!');
          resetForm(); // Clear the form
        } else {
          setError('Failed to submit product movement.');
        }
      } catch (error) {
        console.error('Error submitting product movement:', error); // Debugging error
        setError('Error submitting product movement.');
      }
    } else {
      alert('Please complete all fields before confirming.');
    }
  };




  // Reset form
  const resetForm = () => {
    setScannedBarcode('');
    setProductName('');
    setStatus('');
    setPassword('');
    setUserId(null);
    setUserName('');
    setShowPasswordField(false);
    setShowConfirmButton(false);
    setError('');
  };

  return (
    <div className="barcode-scanner-page">
      <h2>Barcode Scanner</h2>
      {error && <div className="error">{error}</div>}
      <button
        className="scan-btn"
        onClick={() => {
          if (hiddenInputRef.current) {
            hiddenInputRef.current.focus(); // Ensure the input is focused
          }
        }}
      >
        Scan
      </button>
      <input
        ref={hiddenInputRef}
        type="text"
        style={{ opacity: 0, position: 'absolute' }}
        onChange={handleInputChange}
      />
      <form className="scan-form">
        <div className="form-group">
          <label>SKU Number:</label>
          <input type="text" value={scannedBarcode} readOnly />
        </div>

        {productName && (
          <div className="form-group">
            <label>Product Name:</label>
            <input type="text" value={productName} readOnly />
          </div>
        )}

        <div className="form-group">
          <label>Status:</label>
          <select value={status} onChange={handleStatusChange}>
            <option value="">Select</option>
            <option value="In">In</option>
            <option value="Out">Out</option>
            {/* Conditionally render "Unreturnable" option for Manager and Admin */}
            {(role === 'Manager' || role === 'Admin') && (
              <option value="Unreturnable">Unreturnable</option>
            )}
            <option value="Inactive">Inactive</option>
          </select>
        </div>


        {showPasswordField && (
          <div className="form-group">
            <label>Enter Password:</label>
            <input type="password" value={password} onChange={handlePasswordChange} />
          </div>
        )}

        {userId && (
          <div className="form-group">
            <label>User ID:</label>
            <input type="text" value={userId} readOnly />
          </div>
        )}

        {userName && (
          <div className="form-group">
            <label>User Name:</label>
            <input type="text" value={userName} readOnly />
          </div>
        )}

        <div className="form-group">
          <label>Date:</label>
          <input type="text" value={currentDate} readOnly />
        </div>

        {showConfirmButton && (
          <button type="button" className="confirm-btn" onClick={handleConfirm}>
            Confirm
          </button>
        )}
      </form>
    </div>
  );
};

export default BarcodeScanner;