import React, { useState } from 'react';
import '../styles/Support.css';

const Support = () => {
  const [form, setForm] = useState({
    name: '',
    email: '',
    subject: '',
    description: ''
  });
  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await fetch('http://localhost:8080/api/support/submit', { // Update URL as needed
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(form),
      });
  
      if (response.ok) {
        setStatus('Support ticket raised successfully.');
        setForm({ name: '', email: '', subject: '', description: '' }); // Clear the form
      } else {
        setStatus('Failed to raise a support ticket. Please try again later.');
      }
    } catch (error) {
      console.error('Error:', error);
      setStatus('An error occurred. Please try again later.');
    }
  };
  
  return (
    <div className="support-page">
      <h2>Help & Support</h2>
      <p>If you are experiencing issues, please raise a ticket and our support team will assist you.</p>
      <form onSubmit={handleSubmit} className="support-form">
        <div className="form-group">
          <label>Name</label>
          <input type="text" name="name" value={form.name} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label>Email</label>
          <input type="email" name="email" value={form.email} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label>Subject</label>
          <input type="text" name="subject" value={form.subject} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label>Description</label>
          <textarea name="description" value={form.description} onChange={handleChange} required />
        </div>
        <div class="support-form-buttons">
    <button type="submit" class="submit-btn">Submit</button>
    <button type="button" class="cancel-btn">Cancel</button>
  </div>
      </form>
      {status && <p className="status-message">{status}</p>}
    </div>
  );
};

export default Support;
