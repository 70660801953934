import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Pagination } from '@mui/material';
import '../styles/productmovement.css';

const ProductMovement = () => {
  const [movements, setMovements] = useState([]);
  const [page, setPage] = useState(1); // Current page (Material UI Pagination uses 1-based indexing)
  const [rowsPerPage, setRowsPerPage] = useState(10); // Rows per page

  const fetchMovements = async () => {
    try {
      const response = await axios.get('http://localhost:8080/api/barcode/movements');
      setMovements(response.data);
    } catch (error) {
      alert('Error fetching movements');
    }
  };

  useEffect(() => {
    fetchMovements();
  }, []);

  // Define columns for the Material UI Table component
  const columns = [
    { key: 'userId', label: 'User ID' },
    { key: 'userName', label: 'User Name' },
    { key: 'productName', label: 'Product Name' },
    { key: 'scannedBarcode', label: 'SKU' },
    { key: 'status', label: 'Status' },
    { key: 'currentDate', label: 'Date' },
  ];

  // Pagination logic: Slice the movements data based on the current page and rows per page
  const paginatedMovements = movements.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  // Handle the page change
  const handlePageChange = (event, value) => {
    setPage(value); // Set the current page when the user clicks on pagination controls
  };

  return (
    <div className="movement-container" style={{ marginLeft: '270px' }}>
      <h2>Product Movements</h2>
      {movements.length > 0 ? (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow style={{ backgroundColor: 'lightgrey' }}>
                  {columns.map((column) => (
                    <TableCell key={column.key}>{column.label}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedMovements.map((movement, index) => (
                  <TableRow key={index}>
                    {columns.map((column) => (
                      <TableCell key={column.key}>{movement[column.key]}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Pagination Controls */}
          <div className="pagination-controls" style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
            <Pagination
              count={Math.ceil(movements.length / rowsPerPage)} // Total number of pages
              page={page} // Current page
              onChange={handlePageChange} // Handle page change
              color="primary"
            />
          </div>
        </>
      ) : (
        <div>No movements found</div>
      )}
    </div>
  );
};

export default ProductMovement;
