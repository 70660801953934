import React, { createContext, useContext, useState } from 'react';

const ScanContext = createContext();

export const ScanProvider = ({ children }) => {
  const [scannedSKU, setScannedSKU] = useState('');

  return (
    <ScanContext.Provider value={{ scannedSKU, setScannedSKU }}>
      {children}
    </ScanContext.Provider>
  );
};

export const useScan = () => useContext(ScanContext);
