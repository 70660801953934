import React, { useState, useEffect } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Button,
  CircularProgress,
  Grid,
  Card,
  CardContent,
  TablePagination,
} from '@mui/material';
import axios from 'axios';
import { CSVLink } from 'react-csv';

const UsersTablePage = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [usernameFilter, setUsernameFilter] = useState('');
  const [roleFilter, setRoleFilter] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    axios.get('http://localhost:8080/api/logins')
      .then((response) => {
        setUsers(response.data);
        setFilteredUsers(response.data); // Initially, no filter, show all users
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching users:', error);
        setIsLoading(false);
      });
  }, []);

  const handleUsernameFilterChange = (e) => {
    setUsernameFilter(e.target.value);
  };

  const handleRoleFilterChange = (e) => {
    setRoleFilter(e.target.value);
  };

  const applyFilters = () => {
    let filteredData = users;
    if (usernameFilter) {
      filteredData = filteredData.filter(user =>
        user.username.toLowerCase().includes(usernameFilter.toLowerCase())
      );
    }

    if (roleFilter) {
      filteredData = filteredData.filter(user =>
        user.role.toLowerCase() === roleFilter.toLowerCase()
      );
    }

    setFilteredUsers(filteredData);
    setPage(0); // Reset to the first page after filtering
  };

  const handleApplyFilters = (e) => {
    e.preventDefault();
    applyFilters();
  };

  // Handle pagination changes
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page when changing rows per page
  };

  const paginatedUsers = filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <div style={{ paddingLeft: '260px', paddingTop: '20px' }}>
      <Card>
        <CardContent>
          <h2>User List</h2>

          {isLoading ? (
            <CircularProgress />
          ) : (
            <div>
              {/* Filter and Export options */}
              <div style={{ marginBottom: '20px' }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel>Username</InputLabel>
                      <Select
                        value={usernameFilter}
                        onChange={handleUsernameFilterChange}
                        label="Username"
                      >
                        <MenuItem value="">All Users</MenuItem>
                        {users.map((user) => (
                          <MenuItem key={user.id} value={user.username}>
                            {user.username}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel>Role</InputLabel>
                      <Select
                        value={roleFilter}
                        onChange={handleRoleFilterChange}
                        label="Role"
                      >
                        <MenuItem value="">All Roles</MenuItem>
                        <MenuItem value="Admin">Admin</MenuItem>
                        <MenuItem value="User">User</MenuItem>
                        <MenuItem value="Manager">Manager</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={2}>
                    <Button type="submit" variant="contained" color="primary" fullWidth onClick={handleApplyFilters}>
                      Apply Filters
                    </Button>
                  </Grid>

                  <Grid item xs={12} sm={2}>
                    <CSVLink
                      data={filteredUsers}
                      headers={[
                        { label: 'Username', key: 'username' },
                        { label: 'Full Name', key: 'name' },
                        { label: 'Email', key: 'email' },
                        { label: 'Password', key: 'password' },
                        { label: 'Role', key: 'role' },
                      ]}
                      filename="users_list.csv"
                    >
                      <Button variant="contained" color="secondary" fullWidth>
                        Export to CSV
                      </Button>
                    </CSVLink>
                  </Grid>
                </Grid>
              </div>

              {/* Table displaying user data */}
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Username</TableCell>
                      <TableCell>Full Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Password</TableCell>
                      <TableCell>Role</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedUsers.map(user => (
                      <TableRow key={user.id}>
                        <TableCell>{user.username}</TableCell>
                        <TableCell>{user.name}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>{user.password}</TableCell>
                        <TableCell>{user.role}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* Pagination */}
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={filteredUsers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default UsersTablePage;
