import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Home from './pages/Home';
import User from './pages/User';
import Newuser from './pages/Newuser';
import ProductDetail from './pages/ProductDetail';
import ProductMovement from './pages/ProductMovement';
import Newitem from './pages/Newitem';
import BarcodeScanner from './pages/BarcodeScanner';
import OrderedProduct from './pages/OrderedProduct';
import Support from './pages/Support';
import { ScanProvider } from './pages/ScanContext';
import CreateUser from './pages/CreateUser';
import CreateUserTable from './pages/CraeteUserTable';
import Login from './pages/Login';

const App = () => {
  // Initialize authentication state based on localStorage
  const [isAuthenticated, setIsAuthenticated] = useState(
    () => !!localStorage.getItem('authToken') // Check if authToken exists
  );

  const [scannedSKU, setScannedSKU] = useState(null);
  const [users, setUsers] = useState([]);
  const [movements, setMovements] = useState([]);
  const [products, setProducts] = useState([]);
  const [orders, setOrders] = useState([]);
  const [groups, setGroups] = useState(['Item 1', 'Item 2']);

  const addGroup = (groupName) => {
    setGroups((prevGroups) => [...prevGroups, groupName]);
  };

  const addMovement = (movement) => {
    setMovements((prevMovements) => [...prevMovements, movement]);
  };

  const handleLogout = () => {
    // Clear localStorage and update authentication state
    localStorage.clear();
    setIsAuthenticated(false);
  };

  useEffect(() => {
    // Sync isAuthenticated state with localStorage
    if (isAuthenticated) {
      localStorage.setItem('authToken', 'true'); // You can set a placeholder value
    } else {
      localStorage.removeItem('authToken');
    }
  }, [isAuthenticated]);

  return (
    <ScanProvider>
      <Router>
        <div className="app">
          {/* Sidebar is only rendered if authenticated */}
          {isAuthenticated && <Sidebar handleLogout={handleLogout} />}
          
          <div className="content">
            <Routes>
              {/* Redirect to /home if authenticated, otherwise show Login */}
              <Route
                path="/login"
                element={isAuthenticated ? <Navigate to="/home" /> : <Login setIsAuthenticated={setIsAuthenticated} />}
              />
              
              {/* Default route redirects to login if not authenticated */}
              <Route
                path="/"
                element={isAuthenticated ? <Navigate to="/home" /> : <Navigate to="/login" />}
              />
              
              {/* Protected Routes - Only accessible when authenticated */}
              <Route
                path="/home"
                element={isAuthenticated ? (
                  <Home
                    products={products}
                    movements={movements}
                    orderCount={orders.length}
                    groups={groups}
                  />
                ) : (
                  <Navigate to="/login" />
                )}
              />
              <Route
                path="/users"
                element={isAuthenticated ? <User users={users} setUsers={setUsers} /> : <Navigate to="/login" />}
              />
              <Route
                path="/newuser"
                element={isAuthenticated ? <Newuser users={users} setUsers={setUsers} /> : <Navigate to="/login" />}
              />
              <Route
                path="/create-user"
                element={isAuthenticated ? <CreateUser /> : <Navigate to="/login" />}
              />
              <Route
                path="/createuser-table"
                element={isAuthenticated ? <CreateUserTable /> : <Navigate to="/login" />}
              />
              <Route
                path="/productdetail"
                element={isAuthenticated ? (
                  <ProductDetail
                    products={products}
                    setProducts={setProducts}
                    groups={groups}
                    scannedSKU={scannedSKU}
                  />
                ) : (
                  <Navigate to="/login" />
                )}
              />
              <Route
                path="/productmovement"
                element={isAuthenticated ? <ProductMovement movements={movements} users={users} /> : <Navigate to="/login" />}
              />
              <Route
                path="/newitem"
                element={isAuthenticated ? (
                  <Newitem
                    addProduct={(product) => setProducts([...products, product])}
                    groups={groups}
                    addGroup={addGroup}
                  />
                ) : (
                  <Navigate to="/login" />
                )}
              />
              <Route
                path="/barcodescanner"
                element={isAuthenticated ? (
                  <BarcodeScanner
                    users={users}
                    products={products}
                    onScanComplete={setScannedSKU}
                    addMovement={addMovement}
                  />
                ) : (
                  <Navigate to="/login" />
                )}
              />
              <Route
                path="/orderedproduct"
                element={isAuthenticated ? <OrderedProduct orders={orders} setOrders={setOrders} /> : <Navigate to="/login" />}
              />
              <Route
                path="/support"
                element={isAuthenticated ? <Support /> : <Navigate to="/login" />}
              />
            </Routes>
          </div>
        </div>
      </Router>
    </ScanProvider>
  );
};

export default App;
