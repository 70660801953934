import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TablePagination ,TableHead, TableRow, Paper, Dialog, DialogActions, DialogContent, DialogTitle, Button, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import '../styles/productdetail.css';

const ProductDetail = ({ onProductAdded }) => {
  const [products, setProducts] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [searchGroup, setSearchGroup] = useState('');
  const [page, setPage] = useState(0); // Current page for pagination
  const [rowsPerPage, setRowsPerPage] = useState(10); // Rows per page for pagination
  const navigate = useNavigate();

 

  useEffect(() => {
    fetch('http://localhost:8080/api/products')
      .then((response) => response.json())
      .then((data) => setProducts(data))
      .catch((error) => console.error('Error fetching products:', error));

    fetch('http://localhost:8080/api/groups')
      .then((response) => response.json())
      .then((data) => setGroups(data))
      .catch((error) => console.error('Error fetching groups:', error));
  }, []);

  const addProduct = async (newProduct) => {
    try {
      const response = await fetch('http://localhost:8080/api/products', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newProduct),
      });

      if (response.ok) {
        const createdProduct = await response.json();
        setProducts((prevProducts) => [...prevProducts, createdProduct]);

        if (onProductAdded) {
          onProductAdded(createdProduct);
        }
      } else {
        console.error('Error adding product:', response.statusText);
      }
    } catch (error) {
      console.error('Error adding product:', error);
    }
  };

  const goToNewItemPage = () => {
    navigate('/newitem');
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedProduct(null);
  };

  const filteredProducts = products.filter((product) =>
    searchGroup ? product.group === searchGroup : true
  );


  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page when rows per page is changed
  };

  // Slice the filtered products to show only the rows for the current page
  const paginatedProducts = filteredProducts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <div className="Product-page" style={{ backgroundColor: 'white', marginLeft: '280px', backgroundColor: 'transparent' ,width:'1000px'}}>
      <div className="header">
        <h2>All Items</h2>
        <button className="new-product-btn" onClick={goToNewItemPage}>
          New Item
        </button>
      </div>

      <div className="search-bar"  style={{width:'200px' ,marginBottom:'20px'}}>
        <FormControl fullWidth>
          <InputLabel>Search by Group</InputLabel>
          <Select
            value={searchGroup}
            onChange={(e) => setSearchGroup(e.target.value)}
            label="Search by Group"
            className="group-dropdown"
          >
            <MenuItem value="">All Groups</MenuItem>
            <MenuItem value="Item 1">Item 1</MenuItem> {/* Reset Option */}
            {groups.length > 0 ? (
              groups.map((group, index) => (
                <MenuItem key={index} value={group}>
                  {group}
                </MenuItem>
              ))
            ) : (
              <MenuItem>No groups available</MenuItem>
            )}
          </Select>
        </FormControl>
      </div>

      <div className="product-list" style={{marginLeft:'80px'}}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead style={{backgroundColor:'lightgrey'}}>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>SKU</TableCell>
                <TableCell>HSN Code</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Rate</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredProducts.map((product) => (
                <TableRow key={product.sku} hover onClick={() => handleProductClick(product)}>
                  <TableCell>{product.name}</TableCell>
                  <TableCell>{product.sku}</TableCell>
                  <TableCell>{product.hsn}</TableCell>
                  <TableCell>{product.type}</TableCell>
                  <TableCell>{product.rate}</TableCell>
                  <TableCell>{product.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]} // Options for rows per page
        component="div"
        count={filteredProducts.length} // Total number of items
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

<Dialog
      open={showModal}
      onClose={closeModal}
      PaperProps={{
        style: {
          padding: '10px',
          borderRadius: '10px',
          maxWidth: '400px',
          width: '90%',
          textAlign: 'center',
          boxShadow: '0 6px 15px rgba(0, 0, 0, 0.2)',
        },
      }}
    >
      <DialogTitle
        style={{
          fontSize: '22px',
          fontWeight: 'bold',
          color: '#333',
          marginBottom: '10px',
          borderBottom: '2px solid #7ea2d1',
          borderHeight:'3px',
          paddingBottom: '5px',
        }}
      >
        {selectedProduct?.name || 'Product Details'}
      </DialogTitle>
      <DialogContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '30px',
          fontSize: '16px',
          color: '#333',
        }}
      >
        <p style={{ display: 'flex', justifyContent: 'space-between',marginRight:'180px'}}>
          <strong style={{ color: '#555',marginLeft:'2px' }}>Group:</strong> {selectedProduct?.group}
        </p>
        <p style={{ display: 'flex', justifyContent: 'space-between' ,marginRight:'180px'}}>
          <strong style={{ color: '#555',marginLeft:'2px' }}>Category:</strong> {selectedProduct?.category || 'N/A'}
        </p>
        <p style={{ display: 'flex', justifyContent: 'space-between',marginRight:'140px' }}>
          <strong style={{ color: '#555',marginLeft:'2px' }}>Date Added:</strong>{' '}
          {selectedProduct?.dateAdded || new Date().toLocaleDateString()}
        </p>
        <p style={{ display: 'flex', justifyContent: 'space-between',marginRight:'40px' }}>
          <strong style={{ color: '#555',marginLeft:'2px' }}>Description:</strong>{' '}
          {selectedProduct?.description || 'No description available'}
        </p>
      </DialogContent>
      <DialogActions
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '20px',
        }}
      >
        <Button
          onClick={closeModal}
          style={{
            backgroundColor: '#e63946',
            color: '#fff',
            padding: '8px 16px',
            borderRadius: '5px',
            fontSize: '14px',
            textTransform: 'none',
          }}
          onMouseOver={(e) => (e.target.style.backgroundColor = '#d62828')}
          onMouseOut={(e) => (e.target.style.backgroundColor = '#e63946')}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
    </div>
  );
};

export default ProductDetail;
