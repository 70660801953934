import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Card, CardContent, Grid, TextField, Button, Box, InputLabel, Select, MenuItem } from '@mui/material';
import '../styles/newuser.css';

const NewuserPage = ({ users, setUsers }) => {
  const navigate = useNavigate();
  const location = useLocation();

  // Check if we are editing or adding a user
  const [user, setUser] = useState(location.state?.user || {});
  const isEdit = location.state && location.state.user;

  // Local form state for user data
  const [formData, setFormData] = useState({
    userId: '',
    name: isEdit ? user.name : '',
    email: isEdit ? user.email : '',
    dob: isEdit ? user.dob : '',
    mobile: isEdit ? user.mobile : '',
    address: isEdit ? user.address : '',
    role: isEdit ? user.role : '',
    password: isEdit ? user.password : '',
    profileImage: isEdit ? user.profileImage : null,
    id: isEdit ? user.id : null,
  });

  // Fetch the next available user ID from the backend
  useEffect(() => {
    if (!isEdit) {
      axios.get('http://localhost:8080/api/users/nextUserId')
        .then(response => {
          setFormData((prevData) => ({
            ...prevData,
            userId: response.data.toString(),
          }));
        })
        .catch(error => {
          console.error('Error fetching next user ID:', error);
        });
    }
  }, [isEdit]);

  // Handle change in form fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    setFormData({
      ...formData,
      profileImage: e.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isEdit) {
      await updateUser(formData);
    } else {
      await addUser(formData);
    }
  };

  // Add a new user (POST)
  const addUser = async (userData) => {
    const formDataToSend = new FormData();
    formDataToSend.append('name', userData.name);
    formDataToSend.append('email', userData.email);
    formDataToSend.append('dob', userData.dob);
    formDataToSend.append('mobile', userData.mobile);
    formDataToSend.append('address', userData.address);
    formDataToSend.append('role', userData.role);
    formDataToSend.append('userid', userData.userId);
    formDataToSend.append('password', userData.password);

    if (userData.profileImage) {
      formDataToSend.append('profileImage', userData.profileImage);
    }

    try {
      const response = await axios.post('http://localhost:8080/api/users/add', formDataToSend);
      setUsers((prevUsers) => [...prevUsers, response.data]);
      navigate('/users');
    } catch (error) {
      console.error('Error adding user:', error.response || error.message || error);
      alert('There was an issue adding the user. Please try again.');
    }
  };

  // Update an existing user (PUT)
  const updateUser = async (userData) => {
    const formDataToSend = new FormData();
    formDataToSend.append('name', userData.name);
    formDataToSend.append('email', userData.email);
    formDataToSend.append('dob', userData.dob);
    formDataToSend.append('mobile', userData.mobile);
    formDataToSend.append('address', userData.address);
    formDataToSend.append('role', userData.role);
    formDataToSend.append('userid', userData.userId);
    formDataToSend.append('password', userData.password);

    if (userData.profileImage) {
      formDataToSend.append('profileImage', userData.profileImage);
    }

    try {
      const response = await axios.put(`http://localhost:8080/api/users/${userData.id}`, formDataToSend);
      setUsers((prevUsers) =>
        prevUsers.map((user) => (user.id === userData.id ? { ...user, ...response.data } : user))
      );
      navigate('/users');
    } catch (error) {
      console.error('Error updating user:', error.response || error.message || error);
    }
  };

  const handleCancel = () => {
    navigate('/users');
  };

  return (
    <div className="add-user-page">
      <Card sx={{ width: '600px', marginLeft: '480px', padding: '0px' ,marginTop:'80px',backgroundColor:'#edf4f8' }}>
        <CardContent>
          <h2>{isEdit ? 'Edit User' : 'Add New User'}</h2>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3} sx={{ maxWidth: '100%', marginLeft: '20px', marginTop: 2 }}>
              {/* User ID */}
              <Grid item xs={5} sm={5}>
                <TextField
                  label="User ID (Auto-generated)"
                  name="userId"
                  value={formData.userId}
                  fullWidth
                  readOnly
                  placeholder="Auto-generated User ID"
                />
              </Grid>

              {/* Name */}
              <Grid item xs={5} sm={5}>
                <TextField
                  label="Name*"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  fullWidth
                  required
                  placeholder="Enter user's name"
                />
              </Grid>

              {/* Email */}
              <Grid item xs={5} sm={5}>
                <TextField
                  label="Email*"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  fullWidth
                  required
                  placeholder="Enter user's email"
                />
              </Grid>

              {/* Date of Birth */}
              <Grid item xs={5} sm={5}>
                <TextField
                  label="Date of Birth*"
                  name="dob"
                  value={formData.dob}
                  onChange={handleChange}
                  type="date"
                  fullWidth
                  required
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              {/* Mobile Number */}
              <Grid item xs={5} sm={5}>
                <TextField
                  label="Mobile Number*"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  fullWidth
                  required
                  placeholder="Enter user's mobile number"
                />
              </Grid>

              {/* Address */}
              <Grid item xs={5} sm={5}>
                <TextField
                  label="Address*"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  fullWidth
                  required

                  placeholder="Enter user's address"
                />
              </Grid>

              {/* Password */}
              <Grid item xs={5} sm={5}>
                <TextField
                  label="Password*"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  fullWidth
                  required
                  type="password"
                  placeholder="Enter a password"
                />
              </Grid>


            
              {/* <Grid item xs={5} sm={5}>
                <Select
                  name="role" // Bind to form data `role`
                  value={formData.role} // Set value from formData
                  onChange={handleChange} // Handle value change on selection
                  fullWidth // Make it take full width within the grid item
                  required // Mark the field as required
                  displayEmpty // Ensures placeholder appears when no value is selected
                >
                  <MenuItem value="" disabled>Select Role</MenuItem> 
                   <MenuItem value="Admin">Admin</MenuItem>
                  <MenuItem value="Employee">Employee</MenuItem>
                  <MenuItem value="Manager">Manager</MenuItem>
                </Select>
              </Grid>    */}


              {/* Profile Image */}
              <Grid item xs={5} sm={5}>
                <InputLabel>Profile Image</InputLabel>
                {formData.profileImage && !isEdit ? (
                  <img
                    src={URL.createObjectURL(formData.profileImage)}
                    alt="Profile Preview"
                    style={{ width: '100%', height: 'auto', marginBottom: '10px' }}
                  />
                ) : (
                  formData.profileImage && isEdit && (
                    <img
                      src={`http://localhost:8080/api/users/images/${formData.profileImage}`} // Replace with actual image URL
                      alt="Profile Image"
                      style={{ width: '100px', height: 'auto', marginBottom: '10px' }}
                    />
                  )
                )}
                <input
                  type="file"
                  name="profileImage"
                  onChange={handleImageChange}
                  style={{ width: '100%' }}
                />
              </Grid>




              {/* Submit and Cancel Buttons */}
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button type="submit" variant="contained" color="primary">
                  {isEdit ? 'Update User' : 'Add User'}
                </Button>
                <Button variant="outlined" color="secondary" onClick={handleCancel} sx={{ marginRight:'50px',fontWeight:'bold' }}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default NewuserPage;
