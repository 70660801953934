import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Box, Typography, Container } from '@mui/material';

const Login = ({ setIsAuthenticated }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const [forgotUsername, setForgotUsername] = useState('');
  const [forgotEmail, setForgotEmail] = useState('');
  const [resetEmail, setResetEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [forgotPasswordVisible, setForgotPasswordVisible] = useState(false);
  const [resetPasswordVisible, setResetPasswordVisible] = useState(false);

  const apiUrl = 'http://localhost:8080/api/logins';
  const navigate = useNavigate();

  // Check if the user is already authenticated when the component mounts
  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      setIsAuthenticated(true);  // Set authentication state if token exists
      navigate('/home'); // Navigate to home page if already logged in
    }
  }, [navigate, setIsAuthenticated]);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      // Replace with your actual API endpoint
      const response = await fetch(`http://localhost:8080/api/logins/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();

      if (response.ok) {
        // Extract necessary user details from API response
        const { id, role, email, name } = data;

        // Store user details in localStorage
        localStorage.setItem('userId', id);
        localStorage.setItem('userRole', role);
        localStorage.setItem('username', name || username);
        localStorage.setItem('email', email);

        // Set authentication status
        setIsAuthenticated(true);

        // Redirect to home page
        navigate('/home');
      } else {
        // Handle API errors
        setError(data.message || 'Login failed. Please try again.');
      }
    } catch (err) {
      console.error('Login error:', err);
      setError(err.message || 'An unexpected error occurred.');
    }
  };
  

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${apiUrl}/forgot-password?username=${forgotUsername}&email=${forgotEmail}`
      );
      if (response.status === 200) {
        alert('OTP sent successfully to your email.');
        setForgotPasswordVisible(false);
        setResetPasswordVisible(true);
      }
    } catch (error) {
      console.error("Forgot password error:", error);
      alert('Failed to send OTP. Please check your username/email.');
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      alert("Passwords don't match!");
      return;
    }
    try {
      const response = await axios.post(
        `${apiUrl}/reset-password`,
        { email: resetEmail, otp, newPassword, confirmPassword }
      );
      if (response.status === 200) {
        alert('Password reset successful!');
        setResetPasswordVisible(false);
      }
    } catch (error) {
      console.error("Reset password error:", error);
      alert('Failed to reset password. Please check the OTP and try again.');
    }
  };

  return (
    <Box
      sx={{
        backgroundImage: 'url("/Assets/bg.jpeg")', // Correct path for the image in public folder
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 3,
        backgroundColor: '#010525',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
      }}
    >
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            padding: 4,
            borderRadius: 2,
            boxShadow: 3,
            backgroundColor: 'white',
          }}
        >
          {/* Conditionally render either Login form, Forgot Password form, or Reset Password form */}
          
          {!forgotPasswordVisible && !resetPasswordVisible && (
            <div style={{ width: '100%' }}>
              <Typography component="h1" variant="h5" gutterBottom>
                Login
              </Typography>
              <form onSubmit={handleLogin}>
                <TextField
                  label="Username"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
                <TextField
                  label="Password"
                  type="password"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                {loginError && (
                  <Typography variant="body2" color="error" sx={{ marginBottom: 1 }}>
                    {loginError}
                  </Typography>
                )}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ marginTop: 2 }}
                >
                  Login
                </Button>
              </form>
              <Button
                onClick={() => setForgotPasswordVisible(true)}
                sx={{ marginTop: 2 }}
                color="secondary"
              >
                Forgot Password?
              </Button>
            </div>
          )}

          {/* Forgot Password Form */}
          {forgotPasswordVisible && !resetPasswordVisible && (
            <Box sx={{ marginTop: 3 }}>
              <Typography variant="h6">Forgot Password</Typography>
              <form onSubmit={handleForgotPassword}>
                <TextField
                  label="Username"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={forgotUsername}
                  onChange={(e) => setForgotUsername(e.target.value)}
                  required
                />
                <TextField
                  label="Email"
                  type="email"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={forgotEmail}
                  onChange={(e) => setForgotEmail(e.target.value)}
                  required
                />
                <Button type="submit" variant="contained" fullWidth sx={{ marginTop: 2 }}>
                  Send OTP
                </Button>
                <Button
                  onClick={() => setForgotPasswordVisible(false)}
                  sx={{ marginTop: 2 }}
                  color="secondary"
                >
                  Back to Login
                </Button>
              </form>
            </Box>
          )}

          {/* Reset Password Form */}
          {resetPasswordVisible && (
            <Box sx={{ marginTop: 3 }}>
              <Typography variant="h6">Reset Password</Typography>
              <form onSubmit={handleResetPassword}>
                <TextField
                  label="Email"
                  type="email"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={resetEmail}
                  onChange={(e) => setResetEmail(e.target.value)}
                  required
                />
                <TextField
                  label="OTP"
                  type="text"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  required
                />
                <TextField
                  label="New Password"
                  type="password"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
                <TextField
                  label="Confirm Password"
                  type="password"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
                <Button type="submit" variant="contained" fullWidth sx={{ marginTop: 2 }}>
                  Reset Password
                </Button>
                <Button
                  onClick={() => setResetPasswordVisible(false)}
                  sx={{ marginTop: 2 }}
                  color="secondary"
                >
                  Back to Login
                </Button>
              </form>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default Login;
