import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TablePagination } from '@mui/material';
import { PrimaryButton } from '@fluentui/react';
import '../styles/OrderedProduct.css';

const OrderedProduct = ({ orders, setOrders }) => {
  const [showModal, setShowModal] = useState(false);
  const [groupOptions, setGroupOptions] = useState(["Item 1", "Item 2"]);
  const [newGroup, setNewGroup] = useState('');
  const [orderData, setOrderData] = useState({
    name: '',
    group: '',
    orderedDate: '',
    pickupDate: '',
    quantity: '',
    tempid: ''
  });

  const [page, setPage] = useState(0);  // To manage the current page for pagination
  const [rowsPerPage, setRowsPerPage] = useState(10);  // Number of rows per page
  
  const generatetempid = () => {
    if (orders.length === 0) {
      return 'OR101';  // Return the first ID if no orders exist
    }

    const lastOrder = orders[orders.length - 1];
    if (lastOrder && lastOrder.tempid) {
      const numericPart = parseInt(lastOrder.tempid.slice(2), 10);  // Extract number part from 'OR101'
      return `OR${String(numericPart + 1).padStart(3, '0')}`;  // Increment tempId
    }

    return 'OR101';
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const handleOpenModal = () => {
    const newtempid = generatetempid();  // Generate tempId when opening modal
    setOrderData({
      ...orderData,
      tempid: newtempid,  // Dynamically set the tempId
      group: ''  // Reset group to make sure it's empty initially
    });
    setShowModal(true);
  };

  const handleAddOrder = async (e) => {
    e.preventDefault();

    if (!orderData.tempid || !orderData.group) {
      alert('Error: Temp ID or Group is missing.');
      return;
    }

    const newOrder = {
      ...orderData,
      tempid: orderData.tempid || generatetempid(),
    };

    try {
      const response = await fetch('http://localhost:8080/api/orders', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newOrder),
      });

      if (response.ok) {
        fetchOrders();
        setShowModal(false);
        setOrderData({
          name: '',
          group: '',
          orderedDate: '',
          pickupDate: '',
          quantity: '',
          tempid: '',  // Reset the tempId after submission
        });
      } else {
        console.error('Failed to add order');
      }
    } catch (error) {
      console.error('Error adding order:', error);
    }
  };

  const handleAddGroup = () => {
    if (newGroup && !groupOptions.includes(newGroup)) {
      setGroupOptions([...groupOptions, newGroup]);
      setOrderData({ ...orderData, group: newGroup });
      setNewGroup('');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOrderData({ ...orderData, [name]: value });
  };

  const fetchOrders = async () => {
    try {
      const response = await fetch('http://localhost:8080/api/orders');
      const orders = await response.json();
      setOrders(orders);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  // Function to split text into chunks of a given length
  const splitText = (text, maxLength) => {
    const result = [];
    for (let i = 0; i < text.length; i += maxLength) {
      result.push(text.substring(i, i + maxLength));
    }
    return result;
  };

  const columns = [
    { key: 'name', label: 'Name' },
    { key: 'tempid', label: 'Temp ID' },
    { key: 'group', label: 'Group' },
    { key: 'orderedDate', label: 'Ordered Date' },
    { key: 'pickupDate', label: 'Pickup Date' },
    { key: 'quantity', label: 'Quantity' },
  ];

  // Handle page change for pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);  // Reset to first page when changing rows per page
  };

  return (
    <div className="product-page" style={{ backgroundColor: 'transparent', marginLeft: '280px' }}>
      <div className="header">
        <h2>Ordered Products</h2>
        <PrimaryButton onClick={handleOpenModal}>New Order</PrimaryButton>
      </div>

      {showModal && (
        <div className="modal-overlay" onClick={() => setShowModal(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h3 className="modal-title">New Order</h3>
            <form onSubmit={handleAddOrder} className="new-order-form">
              <div className="form-group">
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  value={orderData.name}
                  onChange={handleInputChange}
                  placeholder="Enter product name"
                  required
                />
              </div>
              <div className="form-group">
                <label>Temp ID</label>
                <input
                  type="text"
                  name="tempId"
                  value={orderData.tempid} // Set tempId from orderData
                  readOnly
                />
              </div>
              <div className="form-group">
                <label>Group</label>
                <select
                  name="group"
                  value={orderData.group}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select Group</option>
                  {groupOptions.map((group, index) => (
                    <option key={index} value={group}>{group}</option>
                  ))}
                </select>
                <div className="add-group">
                  <input
                    type="text"
                    value={newGroup}
                    onChange={(e) => setNewGroup(e.target.value)}
                    placeholder="Add new group"
                  />
                  <button type="button" onClick={handleAddGroup}>Add Group</button>
                </div>
              </div>
              <div className="form-group">
                <label>Ordered Date</label>
                <input
                  type="date"
                  name="orderedDate"
                  value={orderData.orderedDate}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Pickup Date</label>
                <input
                  type="date"
                  name="pickupDate"
                  value={orderData.pickupDate}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Quantity</label>
                <input
                  type="number"
                  name="quantity"
                  value={orderData.quantity}
                  onChange={handleInputChange}
                  min="1"
                  required
                />
              </div>
              <button type="submit" className="save-btn">Save Order</button>
              <button
                type="button"
                className="cancel-btn"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      )}

      <div className="product-list">
        <h3 className="table-title">Order List</h3>
        <TableContainer component={Paper} style={{ border: '1px solid #ddd' }}>
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: 'lightgrey' }}>
                {columns.map((column) => (
                  <TableCell key={column.key}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((order, index) => (
                <TableRow key={index}>
                  {columns.map((column) => (
                    <TableCell key={column.key}>
                      {column.key === 'name' ? (
                        // Break the name into chunks of 15 characters
                        splitText(order[column.key], 15).map((chunk, i) => (
                          <div key={i}>{chunk}</div>
                        ))
                      ) : (
                        order[column.key]
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={orders.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
};

export default OrderedProduct;
