import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import '../styles/Sidebar.css';
import {
  FaHome,
  FaIdCard,
  FaQrcode,
  FaBox,
  FaExchangeAlt,
  FaHeadset,
  FaUserPlus,
  FaSignOutAlt,
  FaUser
} from 'react-icons/fa';

const Sidebar = ({ handleLogout = () => console.warn('handleLogout function is not defined') }) => {
  const [role, setRole] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [isProfilePopupOpen, setIsProfilePopupOpen] = useState(false);

  const navigate = useNavigate();

  // Load user data from localStorage when the component mounts
  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    const storedEmail = localStorage.getItem('email');
    const storedRole = localStorage.getItem('userRole');

    console.log('Stored Username:', storedUsername);
    console.log('Stored Email:', storedEmail);
    console.log('Stored Role:', storedRole);

    setUsername(storedUsername || '');
    setEmail(storedEmail || '');
    setRole(storedRole || '');
  }, []);

  const handleLogoutClick = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('userRole');
    localStorage.removeItem('username');
    localStorage.removeItem('email');
    handleLogout(); // Call the passed `handleLogout` function
    navigate('/login');
  };

  const toggleProfilePopup = () => {
    setIsProfilePopupOpen(!isProfilePopupOpen);
  };

  // Render restricted links based on user role
  const renderLinks = () => {
    if (role === 'Employee') {
      // Employee links (restricted access to certain sections)
      return (
        <>
          <li>
            <NavLink to="/" className={({ isActive }) => (isActive ? 'active' : '')}>
              <FaHome className="icon" />
              <span>Home</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/barcodescanner" className={({ isActive }) => (isActive ? 'active' : '')}>
              <FaQrcode className="icon" />
              <span>Barcode Scanning</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/productdetail" className={({ isActive }) => (isActive ? 'active' : '')}>
              <FaBox className="icon" />
              <span>Product Detail</span>
            </NavLink>
          </li>
        </>
      );
    } else {
      // Admin or other roles can access more sections
      return (
        <>
          <li>
            <NavLink to="/" className={({ isActive }) => (isActive ? 'active' : '')}>
              <FaHome className="icon" />
              <span>Home</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/users" className={({ isActive }) => (isActive ? 'active' : '')}>
              <FaIdCard className="icon" />
              <span>Employee ID</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/barcodescanner" className={({ isActive }) => (isActive ? 'active' : '')}>
              <FaQrcode className="icon" />
              <span>Barcode Scanning</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/productdetail" className={({ isActive }) => (isActive ? 'active' : '')}>
              <FaBox className="icon" />
              <span>Product Detail</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/productmovement" className={({ isActive }) => (isActive ? 'active' : '')}>
              <FaExchangeAlt className="icon" />
              <span>Product Movement</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/orderedproduct" className={({ isActive }) => (isActive ? 'active' : '')}>
              <FaBox className="icon" />
              <span>Ordered Products</span>
            </NavLink>
          </li>
          {role === 'Admin' && (
          <li>
            <NavLink to="/create-user" className={({ isActive }) => (isActive ? 'active' : '')}>
              <FaUserPlus className="icon" />
              <span>Create User</span>
            </NavLink>
          </li>
        )}
          <li>
            <NavLink to="/support" className={({ isActive }) => (isActive ? 'active' : '')}>
              <FaHeadset className="icon" />
              <span>Support</span>
            </NavLink>
          </li>
        </>
      );
    }
  };

  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <h2>BERNACLE</h2>
      </div>

      <ul className="sidebar-links">
        {renderLinks()}
        {/* Profile Link */}
        {/* Profile Link - Button styled like NavLink */}
        <li>
          <button onClick={toggleProfilePopup} className="profile-button sidebar-link">
            <FaUser className="icon" />
            <span>Profile</span>
          </button>
        </li>
      </ul>

      {/* Logout Section */}
      <div className="logout-section">
        <button onClick={handleLogoutClick} className="logout-button">
          <FaSignOutAlt className="icon" />
          <span>Logout</span>
        </button>
      </div>

      {/* Profile Popup */}
      {isProfilePopupOpen && (
       <div
       style={{
         position: 'fixed',
         top: '80%',
         left: '25%',
         transform: 'translate(-50%, -50%)',
         width: '90%',
         maxWidth: '400px',
         backgroundColor: '#fff',
         boxShadow: '0 6px 15px rgba(0, 0, 0, 0.2)',
         borderRadius: '10px',
         zIndex: '1001',
         padding: '20px',
         fontFamily: 'Arial, sans-serif',
         textAlign: 'left',
       }}
     >
       <div
         style={{
           display: 'flex',
           justifyContent: 'space-between',
           alignItems: 'center',
           marginBottom: '20px',
           paddingBottom: '10px',
           borderBottom: '2px solid #7ea2d1',
         }}
       >
         <h3
           style={{
             fontSize: '22px',
             textAlign:'center',
             fontWeight: 'bold',
             color: '#333',
             margin: '0',
           }}
         >
           User Profile
         </h3>
         <button
           onClick={toggleProfilePopup}
           style={{
             fontSize: '18px',
             fontWeight: 'bold',
             color: '#333',
             background: 'none',
             border: 'none',
             cursor: 'pointer',
             transition: 'color 0.3s ease',
           }}
           onMouseOver={(e) => (e.target.style.color = '#e63946')}
           onMouseOut={(e) => (e.target.style.color = '#333')}
         >
           &times;
         </button>
       </div>
       <div
         style={{
           display: 'flex',
           flexDirection: 'column',
           gap: '10px',
         }}
       >
         <p
           style={{
             display: 'flex',
             justifyContent: 'space-between',
             fontSize: '16px',
             color: '#333',
             margin: '0',
           }}
         >
           <strong style={{ fontWeight: '600', color: '#555',marginLeft:'0px' }}>Username:</strong>
           <span style={{ fontWeight: '600',marginRight:'180px'}}>{username || 'Not Available'}</span>
         </p>
         <p
           style={{
             display: 'flex',
             justifyContent: 'space-between',
             fontSize: '16px',
             color: '#333',
             margin: '0',
           }}
         >
           <strong style={{ fontWeight: '600', color: '#555' ,marginLeft:'0px'}}>Email:</strong>
           <span style={{ fontWeight: '600',marginRight:'60px'}}>{email || 'Not Available'}</span>
         </p>
         <p
           style={{
             display: 'flex',
             justifyContent: 'space-between',
             fontSize: '16px',
             color: '#333',
             margin: '0',
           }}
         >
           <strong style={{ fontWeight: '600', color: '#555' ,marginLeft:'0px'}}>Role   :</strong>
           <span  style={{ fontWeight: '600',marginRight:'200px'}}>{role || 'Not Available'}</span>
         </p>
       </div>
       <div
         style={{
           textAlign: 'center',
           marginTop: '20px',
         }}
       >
         {/* <button
           onClick={toggleProfilePopup}
           style={{
             backgroundColor: '#e63946',
             color: 'white',
             border: 'none',
             borderRadius: '5px',
             padding: '8px 16px',
             fontSize: '14px',
             cursor: 'pointer',
             transition: 'background-color 0.3s ease',
           }}
           onMouseOver={(e) => (e.target.style.backgroundColor = '#d62828')}
           onMouseOut={(e) => (e.target.style.backgroundColor = '#e63946')}
         >
           Close
         </button> */}
       </div>
     </div>
     
      )}
    </div>
  );
};

export default Sidebar;
